<template>
  <div style="width: 100%; max-width: 1200px; margin:0 auto;">
    <el-form :model="forms" ref="forms">
      <el-row :gutter="10">
        <el-col :xs="12" :sm="12" :md="12" :lg="6" :xl="6">
          <el-form-item label="操作类型" label-width="100px">
            <el-select v-model="forms.billType" disabled placeholder="操作类型">
              <el-option v-for="item in dict.billTypes" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="6" :xl="6">
          <el-form-item label="单据编号" label-width="100px">
            <el-input v-model="forms.billCode" placeholder="单据编号" disabled></el-input>
          </el-form-item>
        </el-col>
        <!-- <el-col :xs="12" :sm="6" :md="6" :lg="3" :xl="3">
          <el-form-item label="" label-width="0px">
            <el-input v-model="forms.storeCode" disabled style="width:120px; margin-left: 50px;"></el-input>
          </el-form-item>
        </el-col> -->
        <el-col :xs="12" :sm="12" :md="12" :lg="6" :xl="6">
          <el-form-item label="所属公司" label-width="100px">
            <el-tree-select ref="companys" v-model="forms.companyId" :data="companys" check-strictly
              :render-after-expand="false" disabled />
          </el-form-item>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="6" :xl="6">
          <el-form-item label="操作渠道" label-width="100px">
            <el-input v-model="forms.channel" placeholder="入库渠道" disabled style="width:120px;"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :xs="12" :sm="12" :md="12" :lg="6" :xl="6">
          <el-form-item label="单据时间" label-width="100px">
            <el-input v-model="forms.billDate" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="6" :xl="6">
          <el-form-item label="操作人员" label-width="100px">
            <el-input v-model="forms.operatorName" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
          <el-form-item label=" " label-width="100px">
            <el-button type="primary" icon="plus" plain @click="splitEvent()"
              v-if="forms.billType == '组装单'">拆解入库</el-button>
            <el-button type="primary" icon="Select" plain @click="auditEvent()"
              v-if="forms.billType == '拆解单' && forms.status == 0">审核入库</el-button>
            <el-button @click="$router.go(-1)">返回</el-button>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-form-item label="操作备注" label-width="100px">
            <el-input v-model="forms.reason" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-table :data="bounds" stripe size="small">
      <el-table-column>
        <el-table-column type="index" align="center" label="序号" width="45"> </el-table-column>
        <!-- <el-table-column label="数据类型" width="80">
        <template #default="scope">
          <span v-if="scope.row.stockNumber > 0" >成品入库</span>
          <span v-else-if="scope.row.stockNumber < 0">物料出库</span>
          <span v-else>其他</span>
        </template>
</el-table-column> -->
        <el-table-column label="类型" width="150">
          <template #default="scope">
            <!-- <el-button v-if="scope.row.stockNumber > 0" @click="splitEvent(scope.row)" type="primary" link
            size="small">[拆解的成品]</el-button> -->
            <el-link v-if="scope.row.stockNumber > 0" type="primary" :underline="false" disabled>入库货品</el-link>
            <el-link v-if="scope.row.stockNumber < 0" type="warning" :underline="false" disabled>出库货品</el-link>
          </template>
        </el-table-column>

        <el-table-column prop="serialCode" label="货号" width="130" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="certNumber" label="证书号" width="130"> </el-table-column>
        <el-table-column prop="detailCode" label="系统款号(SKU)" width="125" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="brandModelCode" label="品牌款号" width="130" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="detailName" label="名称" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="storeCode" label="库房" width="80" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="channel" label="渠道" width="80" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="stockNumber" label="操作数量" width="70"> </el-table-column>

        <el-table-column prop="weight" label="克重" align="right" width="80"> </el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { fetchBillCode } from "@/api/stock";
export default {
  name: "assembleDetail",
  components: {},
  data() {
    return {
      companys: [],
      forms: {},
      // inbound: { stockNumber: 1 },
      // outbounds: [],
      bounds: [],

      billType: '',
      dict: {
        billTypes: []
      },

      id: '',
    };
  },
  methods: {
    splitEvent() {
      if ((this.forms.newBillCode || '').length == 0) {
        this.$message({
          type: "info",
          message: "新单号生成中请稍后再试",
        });
        return;
      }

      //分两个情况：
      // 一个在库的拆解，直接拆解。
      // 一个已出库并再次入库的拆解。如果是这个情况，则是先判断是否已经有多次出入库和最终入库的记录，然后再执行拆解逻辑。
      let that = this;
      this.$confirm("确认拆解成品数据，本次拆解的原单号：" + this.forms.billCode + "，新单号：" + this.forms.newBillCode + "，拆解将按照原出库信息进行入库。是否提交该拆解单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$kaung.fetch({ url: '/StockBills/PreSplitAssemble?billCode=' + (this.forms.billCode || '') + '&newBillCode=' + (this.forms.newBillCode || '') })
            .then((res) => {
              that.$notify({
                title: (res.code == 200 ? "操作成功" : "操作失败"),
                message: res.content,
                type: res.type,
                duration: 10000
              });
              if (res.code == 200) {
                this.$router.push({ path: "assemble", query: {} });
              }
            });
        })
        .catch(() => {
          that.$message({
            type: "info",
            message: "操作失败",
          });
        });
    },
    auditEvent() {
      let that = this;
      let bounds = this.bounds.filter(res => res.stockNumber > 0);
      this.$confirm("确认审核该拆解单数据，本次拆解单号：" + this.forms.billCode + "，拆解入库数：" + bounds.length + "条。是否确认?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$kaung.fetch({ url: '/StockBills/SubmitSplitAssemble/' + (this.id || '') })
            .then((res) => {
              that.$notify({
                title: (res.code == 200 ? "操作成功" : "操作失败"),
                message: res.content,
                type: res.type,
                duration: 10000
              });
              if (res.code == 200) {
                this.$router.push({ path: "assemble", query: {} });
              }
            });
        })
        .catch(() => {
          that.$message({ type: "info", message: "操作失败" });
        });
    }
  },
  mounted() {
    let that = this;
    this.$kaung.dictionary("stock", "otherbound").then((res) => {
      that.dict.billTypes = res;
    })
    this.$kaung.companys().then((res) => {
      this.companys = res;
      this.forms.companyId = this.companys[0].id;
    });

  },
  created() {
    this.id = this.$route.query.id || '';
    if (this.id) {
      this.$kaung.fetch({ url: '/StockBills/DetailAssemble/' + this.id }).then((res) => {
        this.bounds = res.data.bounds;
        let inbound = res.data.bounds.filter(res => res.stockNumber > 0)[0];
        this.forms = { ...res.data.bill, ...inbound };
        this.forms.companyId = this.bounds[0].companyID;
        //this.outbounds = res.data.bounds.filter(res => res.stockNumber < 0);
        console.log('created bounds', this.bounds);

        if (this.forms.billType == '组装单') { //组装单才需要生成拆解单
          let billItem = this.dict.billTypes.filter(res => res.name == '拆解单')[0];
          fetchBillCode({ billType: billItem.value, prev: billItem.attribute }).then((res) => {
            this.forms.newBillCode = res.data + "-1";
            console.log('newBillCode:' + this.forms.newBillCode);
          });
        }
      });
    }
  },
  watch: {

  },
};
</script>
<style scoped></style>